<script setup lang="ts">
import type { InputTypeHTMLAttribute } from "vue"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<{
  icon?: boolean
  type?: InputTypeHTMLAttribute
  name?: string
  required?: boolean
  placeholder?: string
  disabled?: boolean
  loading?: boolean
  leading?: boolean
  trailing?: boolean
  autofocus?: boolean
  autofocusDelay?: number
}>()
defineEmits(["input", "blur", "change"])
const input = ref<HTMLInputElement>()
const value = defineModel<string>()

const autoFocus = () => {
  if (props.autofocus)
    input.value?.focus()
}

onMounted(() => {
  if (props.autofocus) {
    setTimeout(() => {
      autoFocus()
    }, props.autofocusDelay || 100)
  }
})
</script>

<template>
  <div class="main">
    <input
      ref="input"
      v-model="value"
      :name="name"
      :type="type"
      :required="required"
      :placeholder="placeholder"
      :disabled="disabled"
      v-bind="$attrs"
      class="input-inner"
      focus-visible="outline-none ring-2 ring-$global-blue-primary!"
      :class="{
        'has-leading': leading,
      }"
      @input="onInput"
      @blur="onBlur"
      @change="onChange"
    >
    <slot />

    <span
      v-if="$slots.leading"
      class="leading"
    >
      <slot
        name="leading"
        :disabled="disabled"
        :loading="loading"
      />
    </span>

    <span v-if="loading" />
    <span
      v-else-if="$slots.trailing"
      class="trailing"
    >
      <slot
        name="trailing"
        :disabled="disabled"
        :loading="loading"
      />
    </span>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.main
  @apply relative w-full
.leading
  @apply absolute left-0 top-0 bottom-0 flex items-center pl-3 w-6
.trailing
  @apply
.input-inner
  @apply bg-[#{$menu-bg-input-text-box}] placeholder-[#{$menu-t-input-placeholder}] ring-[#{$menu-b-input-default}]
  @apply relative block w-full border-0 rounded-2 px-3 py-2 disabled:cursor-not-allowed  disabled:opacity-75 focus:outline-none focus:outline-2 focus:outline-$global-blue-primary ring-1
input.has-leading
  @apply pl-8
</style>
